import { useLocation, useNavigate, Link } from "react-router-dom";
import useScrollPosition from "@react-hook/window-scroll";

import * as Auth from "providers/auth";

import { Button, BUTTON_VARIANTS } from "components/Button";

import logo from "assets/general/logo.svg";
import menu from "assets/general/menu.svg";
import logout from "assets/general/logout.svg";

export default function Navbar({
  toggle
}: {
  open: boolean;
  toggle: (value: boolean) => void;
}) {
  const { user, signOut } = Auth.useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const scrollY = useScrollPosition();

  return (
    <nav
      className={
        scrollY > 40
          ? "flex justify-center border-b border-mono-100 sticky top-0 bg-mono-white z-20 w-screen"
          : "flex justify-center md:border-none border-b border-mono-100 sticky top-0 bg-mono-white z-20 w-screen"
      }
    >
      <div className="w-full flex flex-col items-center">
        <div
          className="flex flex-col justify-center w-full sm:mx-16 mx-4"
          style={{ maxWidth: "1312px" }}
        >
          <div className="flex items-center justify-between h-16 w-full sm:px-16 px-4">
            <div className="flex items-center">
              <Link to={user?.id ? "/dashboard" : "/"}>
                <img
                  className="cursor-pointer"
                  alt="crowdfreighter logo"
                  width="180"
                  height="30"
                  src={logo}
                />
              </Link>
              <div className="ml-2 bg-primary px-2 py-0.5 rounded-3xl">
                <p className="caption">Beta</p>
              </div>
            </div>
            {/* desktop menu */}
            <div className="hidden md:flex">
              <div className="flex items-center">
                {user?.id ? (
                  <div className="flex space-x-8 items-center">
                    {user.full_name && (
                      <>
                        <Link
                          className={
                            [
                              "/account-users",
                              "/account-users/create"
                            ].includes(pathname)
                              ? "text-primary"
                              : "text-mono-black"
                          }
                          to="/account-users"
                        >
                          Team
                        </Link>
                        <Link
                          className={
                            pathname === "/dashboard"
                              ? "text-primary"
                              : "text-mono-black"
                          }
                          to="/dashboard"
                        >
                          Dashboard
                        </Link>
                      </>
                    )}
                    <button
                      className="link"
                      title="Sign out"
                      onClick={async () => {
                        try {
                          signOut();

                          return navigate(`/login`);
                        } catch (error: any) {
                          return error.message;
                        }
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <img alt="logout" src={logout} />
                      </div>
                    </button>
                  </div>
                ) : (
                  <div className="space-x-2">
                    {["/", "/privacy-policy", "/terms-of-service"].includes(
                      pathname
                    ) && (
                      <Button
                        text="Login"
                        variant={BUTTON_VARIANTS.SECONDARY}
                        onClick={() => navigate(`/login`)}
                      />
                    )}
                    {["/", "/privacy-policy", "/terms-of-service"].includes(
                      pathname
                    ) && (
                      <Button
                        text="Get Started"
                        onClick={() => navigate(`/register`)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* mobile menu closed */}
            <div className="flex md:hidden">
              <button
                onClick={() => toggle(true)}
                type="button"
                className="inline-flex items-center justify-center p-2 border-none ring-none outline-none"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <img
                  className="cursor-pointer"
                  alt="crowdfreighter logo"
                  width="24"
                  height="24"
                  src={menu}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
