import { Link, useLocation, useNavigate } from "react-router-dom";

import * as Auth from "providers/auth";

import { Button, BUTTON_VARIANTS } from "components/Button";
import linkedinIcon from "assets/social/linkedin.svg";
import instagramIcon from "assets/social/instagram.svg";
import menuClose from "assets/general/menu-close.svg";
import logo from "assets/general/logo.svg";
import logout from "assets/general/logout.svg";

export default function Drawer({
  open,
  toggle
}: {
  open: boolean;
  toggle: (value: boolean) => void;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut } = Auth.useAuth();

  return (
    <main
      className={`fixed overflow-hidden z-30 inset-0 transform bg-mono-white ${
        open ? "opacity-100 translate-x-0" : "translate-x-full"
      }`}
    >
      <section
        className={`w-screen max-w-lg right-0 absolute h-full shadow-xl transform ${
          open ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <nav>
          <div className="mx-auto sm:px-16 px-4 z-0 border-b border-mono-100">
            <div className="flex items-center justify-between w-full h-16">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    className="cursor-pointer"
                    alt="crowdfreighter logo"
                    width="180"
                    height="30"
                    src={logo}
                  />
                </Link>
                <div className="ml-2 bg-primary px-2 py-0.5 rounded-3xl">
                  <p className="caption">Beta</p>
                </div>
              </div>
              <button
                onClick={() => toggle(false)}
                type="button"
                className="inline-flex items-center justify-center p-2 border-none ring-none outline-none"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Close main menu</span>
                <img
                  className="cursor-pointer"
                  alt="crowdfreighter logo"
                  width="24"
                  height="24"
                  src={menuClose}
                />
              </button>
            </div>
          </div>
        </nav>
        <div className="flex items-center justify-center">
          <div className="fixed inset-y-0 top-20 left-0 right-0 bg-mono-white">
            <div className="px-4 space-y-6 flex flex-grow flex-col h-full pb-40 space-y-6 justify-center items-center">
              {user ? (
                <>
                  <Link
                    className={
                      ["/account-users", "/account-users/create"].includes(
                        location.pathname
                      )
                        ? "text-primary"
                        : "text-mono-black"
                    }
                    to="/account-users"
                  >
                    Team
                  </Link>
                  <Link
                    className={
                      location.pathname === "/dashboard"
                        ? "text-primary"
                        : "text-mono-black"
                    }
                    onClick={() => toggle(false)}
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </>
              ) : (
                <>
                  <Button
                    className="w-full md:w-auto"
                    text="Login"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={() => {
                      toggle(false);
                      navigate(`/login`);
                    }}
                  />
                  <Button
                    className="w-full md:w-auto"
                    text="Register"
                    onClick={() => {
                      toggle(false);
                      navigate(`/register`);
                    }}
                  />
                </>
              )}
            </div>
            <div className="absolute bottom-0 right-0 left-0 pb-4">
              {user && (
                <div className="flex justify-center">
                  <img alt="logout" className="mr-2" src={logout} />
                  <Button
                    text="Sign out"
                    variant={BUTTON_VARIANTS.LINK}
                    onClick={async () => {
                      try {
                        toggle(false);

                        await signOut();

                        return navigate(`/login`);
                      } catch (error: any) {
                        return error.message;
                      }
                    }}
                  />
                </div>
              )}
              <div className="mt-4 py-4 flex items-center justify-center space-x-2 px-12 border-t border-mono-100">
                <a
                  href="https://www.linkedin.com/company/crowdfreighter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="linked in" src={linkedinIcon} />
                </a>
                <a
                  href="https://www.instagram.com/crowdfreighter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="instagram" src={instagramIcon} />
                </a>
              </div>
              <div className="flex space-x-6 justify-center items-center">
                <Link
                  to="/privacy-policy"
                  className="caption"
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms-of-service"
                  className="caption"
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  Terms of Service
                </Link>
              </div>
              <div className="flex justify-center mt-5">
                <p className="caption text-mono-black text-opacity-40">
                  © 2022 Open Chair Technologies, Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
