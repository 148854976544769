import React from "react";
import { GoogleLoginResponse } from "react-google-login";

import {
  authenticateEmail,
  authenticateOauth,
  authenticateOtp,
  AuthenticateResponse,
  AuthenticateOTPResponse
} from "models/auth";

interface UpdateAuthUser {
  full_name: string;
  phone_number: string | null;
}

interface AuthContextInterface {
  fetchToken: () => AuthenticateResponse | null;
  requestOtp: (email: string) => Promise<AuthenticateOTPResponse | undefined>;
  signIn: (
    email: string,
    verification_code: string
  ) => Promise<AuthenticateResponse | undefined>;
  signInApple: (response: any) => Promise<AuthenticateResponse | undefined>;
  signInGoogle: (response: any) => Promise<AuthenticateResponse | undefined>;
  signOut: () => void;
  updateAuthUser: (value: UpdateAuthUser) => AuthenticateResponse;
  user: AuthenticateResponse | null;
}

interface Props {
  children: React.ReactNode;
}

const AuthStateContext = React.createContext<AuthContextInterface | undefined>(
  undefined
);

function AuthProvider(props: Props) {
  const { children } = props;
  const [user, setUser] = React.useState<AuthenticateResponse | null>(null);

  React.useEffect(() => {
    setUser(fetchToken());
  }, []);

  function fetchToken(): AuthenticateResponse | null {
    const item = localStorage.getItem("com.crowdfreighter.web");

    if (item) {
      const parsed = JSON.parse(item);

      return parsed;
    }

    return null;
  }

  const requestOtp = async (email: string) => {
    try {
      const response = await authenticateOtp(email);

      return response;
    } catch (err) {}
  };

  const signIn = async (email: string, verification_code: string) => {
    try {
      const user = await authenticateEmail(email, verification_code);

      localStorage.setItem("com.crowdfreighter.web", JSON.stringify(user));
      setUser(user);

      return user;
    } catch (err) {}
  };

  const signInApple = async (response: any) => {
    try {
      const user = await authenticateOauth({
        provider: "apple_web",
        id_token: response.authorization.id_token
      });

      localStorage.setItem("com.crowdfreighter.web", JSON.stringify(user));
      setUser(user);

      return user;
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        // handle that the user canceled the sign-in flow
      } else {
        // handle other errors
      }
    }
  };

  const signInGoogle = async (response: GoogleLoginResponse) => {
    try {
      const user = await authenticateOauth({
        provider: "google",
        id_token: response.getAuthResponse().id_token
      });

      localStorage.setItem("com.crowdfreighter.web", JSON.stringify(user));
      setUser(user);

      return user;
    } catch (err) {}
  };

  const signOut = () => {
    setUser(null);
    localStorage.removeItem("com.crowdfreighter.web");
  };

  const updateAuthUser = ({ full_name, phone_number }: UpdateAuthUser) => {
    const updatedUser = {
      ...user,
      full_name,
      phone_number
    } as AuthenticateResponse;

    localStorage.setItem("com.crowdfreighter.web", JSON.stringify(updatedUser));
    setUser(updatedUser);

    return updatedUser;
  };

  return (
    <AuthStateContext.Provider
      value={{
        fetchToken,
        requestOtp,
        signIn,
        signInApple,
        signInGoogle,
        signOut,
        updateAuthUser,
        user
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
