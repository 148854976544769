import React from "react";
import axios, { AxiosInstance } from "axios";

import * as Auth from "./auth";

interface AxiosContextInterface {
  api: AxiosInstance;
}

interface Props {
  children: React.ReactNode;
}

const AxiosStateContext = React.createContext<
  AxiosContextInterface | undefined
>(undefined);

export function AxiosProvider(props: Props) {
  const { children } = props;
  const { user } = Auth.useAuth();

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_CORE_URL}`,
    headers: {
      accepts: "application/json",
      "content-type": "application/json"
    }
  });

  api.interceptors.request.use(
    (config) => {
      if (!config?.headers?.authorization) {
        if (config.headers) {
          config.headers.authorization = `Bearer ${user?.access_token}`;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <AxiosStateContext.Provider value={{ api }}>
      {children}
    </AxiosStateContext.Provider>
  );
}

export function useAxios() {
  const context = React.useContext(AxiosStateContext);
  if (context === undefined) {
    throw new Error("useAxios must be used within a AxiosProvider");
  }
  return context;
}
