import React from "react";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import * as Auth from "providers/auth";
interface ReportsContextInterface {
  track: () => void;
}

const ReportsContext = React.createContext<ReportsContextInterface | undefined>(
  undefined
);

export function ReportsProvider(props: any) {
  const { user } = Auth.useAuth();

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0
      });
    }
  }, []);

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (user?.id) {
        Sentry.setUser({ id: user.id });
      }
    }
  }, [user]);

  return (
    <>
      <ReportsContext.Provider {...props} value={{}} />
    </>
  );
}

export function useReports() {
  const context = React.useContext(ReportsContext);

  if (context === undefined) {
    throw new Error("useReports must be used within an ReportsProvider.");
  }

  return context;
}
