import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { useLockBodyScroll } from "react-use";

import * as Auth from "providers/auth";
import { AuthenticateResponse } from "models/auth";

import Navbar from "components/Navbar";
import Drawer from "components/Drawer";

import Authenticate from "routes/Authenticate";
import PrivacyPolicy from "routes/PrivacyPolicy";
import TermsOfService from "routes/TermsOfService";

const AccountUsers = React.lazy(() => import("routes/AccountUsers"));
const AccountUsersCreate = React.lazy(
  () => import("routes/AccountUsersCreate")
);
const AssetCreate = React.lazy(() => import("routes/AssetCreate"));
const Dashboard = React.lazy(() => import("routes/Dashboard"));
const NotFound = React.lazy(() => import("routes/NotFound"));
const OnBoarding = React.lazy(() => import("routes/OnBoarding"));
const Verify = React.lazy(() => import("routes/Verify"));

export default function Router() {
  const { fetchToken } = Auth.useAuth();
  const user = fetchToken();

  const [drawerOpen, toggleDrawer] = React.useState(false);
  useLockBodyScroll(!!drawerOpen);

  return (
    <BrowserRouter>
      <React.Suspense fallback={null}>
        <div className="h-screen flex items-center flex-col w-screen">
          <Navbar open={drawerOpen} toggle={toggleDrawer} />
          <Routes>
            <Route
              path={`/assets/create`}
              element={
                <RequireAuth user={user}>
                  <AssetCreate />
                </RequireAuth>
              }
            />
            <Route
              path={`/account-users`}
              element={
                <RequireAuth user={user}>
                  <AccountUsers />
                </RequireAuth>
              }
            />
            <Route
              path={`/account-users/create`}
              element={
                <RequireAuth user={user}>
                  <AccountUsersCreate />
                </RequireAuth>
              }
            />
            <Route
              path={`/dashboard`}
              element={
                <RequireAuth user={user}>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route path={`/login`} element={<Authenticate />} />;
            <Route
              path={`/on-boarding/*`}
              element={
                <RequireAuth user={user}>
                  <OnBoarding />
                </RequireAuth>
              }
            />
            <Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
            <Route path={`/register`} element={<Authenticate />} />;
            <Route path={`/terms-of-service`} element={<TermsOfService />} />
            <Route path={`/verify`} element={<Verify />} />;
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {/* <Footer /> */}
        <Drawer open={drawerOpen} toggle={toggleDrawer} />
      </React.Suspense>
    </BrowserRouter>
  );
}

interface Props {
  children: JSX.Element;
  user: AuthenticateResponse | null;
}

export function RequireAuth(props: Props) {
  const { user } = props;
  const location = useLocation();

  if (!user?.id) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return props.children;
}
