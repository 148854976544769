import React from "react";
import mixpanel from "mixpanel-browser";
import Helmet from "react-helmet";

import * as Auth from "providers/auth";
interface AnalyticsContextInterface {
  track: (eventName: string | undefined, payload?: any) => void;
}

const AnalyticsContext = React.createContext<
  AnalyticsContextInterface | undefined
>(undefined);

export function AnalyticsProvider(props: any) {
  const { user } = Auth.useAuth();

  React.useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
      return mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
        debug: process.env.NODE_ENV === "development" ? true : false
      });
  }, []);

  React.useEffect(() => {
    if (user?.id) {
      return mixpanel.identify(user.id);
    }
  }, [user]);

  function track(eventName: string, payload?: any) {
    return mixpanel.track(eventName, payload || null);
  }

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-39J5GZ09J9"
        ></script>
        <script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${process.env.REACT_APP_GOOGLE_CLIENT_ID}');
`}</script>
      </Helmet>
      <AnalyticsContext.Provider
        value={{
          track
        }}
        {...props}
      />
    </>
  );
}

export function useAnalytics() {
  const context = React.useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider.");
  }

  return context;
}
