import axios from "axios";
import decode from "jwt-decode";

export type AuthenticateResponse = {
  id: string | null;
  email: string | null;
  full_name: string | null;
  phone_number: string | null;
  account_users: { account_id: string; role: string }[];
  iat: number | null;
  exp: number | null;
  access_token: string | null;
};

export type AuthenticateOTPResponse = {
  data: {
    email: string;
  };
};

export async function authenticateEmail(
  email: string,
  verification_code: string
): Promise<AuthenticateResponse> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/authenticate/email`,
      {
        email,
        verification_code: Number(verification_code)
      }
    );

    const user: AuthenticateResponse = decode(
      response.headers["authorization"]
    );

    return { ...user, access_token: response.headers["authorization"] };
  } catch (err) {
    return err.message;
  }
}

export async function authenticateOauth({
  id_token,
  provider
}: {
  id_token: string;
  provider: string;
}): Promise<AuthenticateResponse> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/authenticate/oauth`,
      {
        id_token,
        provider,
        client: process.env.REACT_APP_APPLE_CLIENT_ID
      }
    );

    const user: AuthenticateResponse = decode(
      response.headers["authorization"]
    );

    return { ...user, access_token: response.headers["authorization"] };
  } catch (err) {
    return err.message;
  }
}

export async function authenticateOtp(
  email: string
): Promise<AuthenticateOTPResponse> {
  try {
    const user: AuthenticateOTPResponse = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/authenticate/otp`,
      {
        email,
        provider: "email",
        client: process.env.REACT_APP_APPLE_CLIENT_ID
      }
    );

    return user;
  } catch (err) {
    return err.message;
  }
}
