import info from "assets/general/info.svg";

export default function TextInput(props: any) {
  const {
    autocomplete = "",
    className = "",
    disabled = false,
    error,
    helper,
    label,
    onBlur,
    onChange,
    onFocus,
    name,
    placeholder,
    value,
    required = false,
    toggle,
    type
  } = props;

  return (
    <div className={className ? className : ""}>
      {label && (
        <div className="flex items-center mb-2">
          <label className="caption">
            {label}
            {required && <span>&nbsp;*</span>}
            {toggle && (
              <button className="link" onClick={toggle}>
                <img src={info} alt="info" />
              </button>
            )}
          </label>
        </div>
      )}
      <input
        className={error ? "error" : ""}
        name={name}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        disabled={disabled}
        autoComplete={autocomplete}
      />
      <div className={error || helper ? "flex" : "hidden"}>
        <p
          className={`my-2 sm:mb-0 text-xs ${
            error ? `text-error` : `text-mono-black`
          }`}
        >
          {!error && helper ? helper : error}
        </p>
      </div>
    </div>
  );
}
