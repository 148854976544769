import { Helmet } from "react-helmet";

export default function TermsOfService() {
  return (
    <>
      <Helmet>
        <title>CrowdFreighter | Terms of Service</title>
        <meta property="og:title" content="CrowdFreighter | Terms of Service" />
      </Helmet>
      <div className="sm:px-16 px-4 flex flex-grow w-full">
        <div className="flex w-full justify-center mt-10">
          <div className="flex flex-col lg:w-2/3 w-full pb-20">
            <div className="flex flex-col items-center">
              <h2>Terms of Service</h2>
            </div>
            <p className="mt-6">
              Last modified: February 16, 2022
              <br />
              <br />
            </p>
            <p className="font-semibold italic">
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
              AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING
              YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO
              ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS
              YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER
              (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST
              CROWDFREIGHTER ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2)
              YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY,
              INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND
              (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US
              RESOLVED BY A JURY OR IN A COURT OF LAW. BY CREATING AN ACCOUNT
              WITH US , OR BY SUBMITTING AN INVESTOR QUESTIONNAIRE, YOU AGREE TO
              BE LEGALLY BOUND BY THESE TERMS OF SERVICE, AND ALL TERMS
              INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE
              TERMS, DO NOT CREATE AN ACCOUNT WITH US, OR SUBMIT AN INVESTOR
              QUESTIONNAIRE.
            </p>
            <div className="mt-6">
              <h5>Introduction</h5>
              <p className="mt-2">Welcome to CrowdFreighter!</p>
              <br />{" "}
              <p>
                We are the first crowdfunding website dedicated for the
                logistics and trucking industry! Open Chair Technologies, Inc.
                (“CrowdFreighter,” “we,” “us,” “our”) provides its services
                (described below) to you through its website located at
                www.crowdfreighter.com (the “Site”) and through its mobile
                applications and related technology and services (collectively,
                such services, including any new features and applications, and
                the Site, the “Service”), subject to the following Terms of
                Service (as amended from time to time, the “Terms of Service”).
                We reserve the right, at our sole discretion, to change or
                modify portions of these Terms of Service at any time. If we do
                this, we will post the changes on this page and will indicate at
                the top of this page the date these terms were last revised. We
                will also notify you, either through the Service user interface,
                in an email notification or through other reasonable means. Any
                such changes will become effective no earlier than fourteen (14)
                days after they are posted, except that changes addressing new
                functions of the Service or changes made for legal reasons will
                be effective immediately. Your continued use of the Service
                after the date any such changes become effective constitutes
                your acceptance of the new Terms of Service.
              </p>
              <br />
              <p>
                In addition, when using certain services will be subject to any
                additional terms applicable to such services that may be posted
                on the Service from time to time, including, without limitation,
                the Privacy Policy located a www.crowdfreighter.com/privacy (the
                “Privacy Policy”). All such terms are hereby incorporated by
                reference into these Terms of Service.
              </p>
            </div>
            <div className="mt-6">
              <h5>Access and Use of the Service</h5>
              <p className="mt-2">
                <span className="font-bold">Service Description:</span>{" "}
                CrowdFreighter provides online tools to assist potential
                accredited investors in accessing the investment class of
                private market logistics and semi-trucks. The Service allows
                investors to diversify their portfolios across a hand-picked
                selection of private market logistics and semi-truck investment
                opportunities thereby allowing investors to unlock new and
                exciting opportunities for portfolio growth.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  Your Registration Obligations:
                </span>{" "}
                You may be required to register with CrowdFreighter in order to
                access and use certain features of the Service. If you choose to
                register for the Service, you agree to provide and maintain
                true, accurate, current and complete information about yourself
                as prompted by the Service’s registration form. Registration
                data and certain other information about you are governed by the
                Privacy Policy. If you are under 18 years of age, you are not
                authorized to use the Service, with or without registering.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  Member Account, Password and Security:
                </span>{" "}
                You are responsible for maintaining the confidentiality of your
                password and account, if any, and are fully responsible for any
                and all activities that occur under your password or account.
                You agree to (a) immediately notify CrowdFreighter of any
                unauthorized use of your password or account or any other breach
                of security, and (b) ensure that you exit from your account at
                the end of each session when accessing the Service.
                CrowdFreighter will not be liable for any loss or damage arising
                from your failure to comply with this Section.
              </p>
              <br />
              <p>
                <span className="font-bold">Modifications to Service:</span>{" "}
                CrowdFreighter reserves the right to modify or discontinue,
                temporarily or permanently, the Service (or any part thereof)
                with or without notice. You agree that CrowdFreighter will not
                be liable to you or to any third party for any modification,
                suspension or discontinuance of the Service.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  General Practices Regarding Use and Storage:
                </span>{" "}
                You acknowledge that CrowdFreighter may establish general
                practices and limits concerning use of the Service, including
                without limitation the maximum period of time that data or other
                content will be retained by the Service and the maximum storage
                space that will be allotted on Crowdfreighter’s servers on your
                behalf. You agree that CrowdFreighter has no responsibility or
                liability for the deletion or failure to store any data or other
                content maintained or uploaded by the Service. You acknowledge
                that CrowdFreighter reserves the right to terminate accounts
                that are inactive for an extended period of time. You further
                acknowledge that CrowdFreighter reserves the right to change
                these general practices and limits at any time, in its sole
                discretion, with or without notice.
              </p>
            </div>
            <div className="mt-6">
              <h5>Conditions of Use</h5>
              <p className="mt-2">
                <span className="font-bold">User Conduct:</span> You are solely
                responsible for all code, video, images, information, data,
                text, software, music, sound, photographs, graphics, messages or
                other materials (“content”) that you upload, post, publish or
                display (hereinafter, “upload”) or email or otherwise use via
                the Service. The following are examples of the kind of content
                and/or use that is illegal or prohibited by CrowdFreighter.
                CrowdFreighter reserves the right to investigate and take
                appropriate legal action against anyone who, in Crowdfreighter’s
                sole discretion, violates this provision, including without
                limitation, removing the offending content from the Service,
                suspending or terminating the account of such violators and
                reporting you to the law enforcement authorities. You agree to
                not use the Service to:
              </p>
              <br />
              <p>
                <ul className="list-none list-inside">
                  <li>
                    <span className="font-bold">a)</span> email or otherwise
                    upload any content that (i) infringes any intellectual
                    property or other proprietary rights of any party; (ii) you
                    do not have a right to upload under any law or under
                    contractual or fiduciary relationships; (iii) contains
                    software viruses or any other computer code, files or
                    programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware or
                    telecommunications equipment; (iv) poses or creates a
                    privacy or security risk to any person; or (v) is unlawful,
                    harmful, threatening, abusive, harassing, tortious,
                    excessively violent, defamatory, vulgar, obscene,
                    pornographic, libelous, invasive of another’s privacy,
                    hateful racially, ethnically or otherwise objectionable;
                  </li>
                  <li>
                    <span className="font-bold">b)</span> interfere with or
                    disrupt the Service or servers or networks connected to the
                    Service, or disobey any requirements, procedures, policies
                    or regulations of networks connected to the Service; or
                  </li>
                  <li>
                    <span className="font-bold">c)</span> violate any applicable
                    local, state, national or international law, or any
                    regulations having the force of law;
                  </li>
                  <li>
                    <span className="font-bold">d)</span> impersonate any person
                    or entity, or falsely state or otherwise misrepresent your
                    affiliation with a person or entity;
                  </li>
                  <li>
                    <span className="font-bold">e)</span> further or promote any
                    criminal activity or enterprise or provide instructional
                    information about illegal activities; or
                  </li>
                  <li>
                    <span className="font-bold">f)</span> obtain or attempt to
                    access or otherwise obtain any materials or information
                    through any means not intentionally made available or
                    provided for through the Service.
                  </li>
                </ul>
              </p>
              <br />
              <p>
                <span className="font-bold">Fees:</span> There are fees
                associated with investments you may make through the Service.
                Any such fees will be disclosed in the offering documents with
                respect to such investments.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  Special Notice for International Use; Export Controls:
                </span>{" "}
                Software available in connection with the Service and the
                transmission of applicable data, if any, is subject to United
                States export controls. No software may be downloaded from the
                Service or otherwise exported or re-exported in violation of
                U.S. export laws. Downloading or using the software is at your
                sole risk. Recognizing the global nature of the Internet, you
                agree to comply with all local rules and laws regarding your use
                of the Service, including as it concerns online conduct and
                acceptable content.
              </p>
              <br />
              <p>
                <span className="font-bold">Commercial Use:</span> Unless
                otherwise expressly authorized herein or in the Service, you
                agree not to display, distribute, license, perform, publish,
                reproduce, duplicate, copy, create derivative works from,
                modify, sell, resell, exploit, transfer or upload for any
                commercial purposes, any portion of the Service, use of the
                Service, or access to the Service. The Service is for your
                personal use.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  Electronic Transactions and Disclosures:
                </span>{" "}
                Because CrowdFreighter operates only on the Internet, it is
                necessary for you to consent to transact business with us online
                and electronically. As part of doing business with us and our
                affiliates (“Our Affiliates”), therefore, we also need you to
                consent to our giving you certain disclosures electronically,
                either via our Site or to the email address you provide to us.
                By agreeing to the Terms of Service, you agree to receive
                electronically all documents, communications, notices,
                contracts, and agreements (including any IRS Forms, including
                Form 1099 and Schedule K-1) arising from or relating to your use
                of the Site and Service, including any notes you have purchased,
                your use of this Service, and the servicing of any notes you
                have purchased as either an investor of CrowdFreighter (each, a
                “Disclosure”), from us, whether we are acting in the capacity as
                trustee or otherwise, or Our Affiliates. An IRS Form refers to
                any Form 1099, Schedule K-1 or other Form, Schedule or
                information statement, including corrections of such documents,
                required to be provided pursuant to U.S. Internal Revenue
                Service rules and regulations and that may be provided
                electronically (each, an “IRS Form”), provided that you consent
                to such electronic delivery, as more fully described in the
                subscription document(s) applicable to you.
              </p>
              <br />
              <p>
                <span className="font-bold">Electronic Communications:</span>{" "}
                Any Disclosures will be provided to you electronically through
                CrowdFreighter.com either on our Site or via electronic mail to
                the verified email address you provided. If you require paper
                copies of such Disclosures, you may write to us at the mailing
                address provided below and a paper copy will be sent to you at
                no extra cost. A request for a paper copy of any Disclosure will
                not be considered a withdrawal of your consent to receive
                Disclosures electronically. Any IRS Forms provided
                electronically will remain accessible for twelve months
                following the end of the tax year to which the IRS Forms relate,
                or six months after the date of issuance of the IRS Forms,
                whichever is later; after that time the IRS Forms may no longer
                be accessible electronically. We may discontinue electronic
                provision of Disclosures at any time in our sole discretion, in
                which case we will provide you with paper copies.
              </p>
              <br />
              <p>
                <span className="font-bold">Scope of Consent:</span> Your
                consent to receive Disclosures and transact business
                electronically, and our agreement to do so, applies to any
                transactions to which such Disclosures relate, whether between
                you and CrowdFreighter or between you and Our Affiliates. Your
                consent will remain in effect for so long as you are a User and,
                if you are no longer a User, will continue until such a time as
                all Disclosures relevant to transactions that occurred while you
                were a User have been made, subject to any special rules
                regarding consent to electronic delivery of IRS Forms, as more
                fully described in the [subscription document(s) applicable to
                you.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  Consenting to Do Business Electronically:
                </span>{" "}
                Before you decide to do business electronically with
                CrowdFreighter or Our Affiliates, you should consider whether
                you have the required hardware and software capabilities
                described below.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  Hardware and Software Requirements:
                </span>{" "}
                In order to access and retain Disclosures electronically, you
                must satisfy the following computer hardware and software
                requirements: access to the Internet; an email account and
                related software capable of receiving email through the
                Internet; supported Web browsing software (three most recent
                versions of Google Chrome, Microsoft Edge or Firefox, Internet
                Explorer version 11 or higher, or Safari version 10 or higher);
                and hardware capable of running this software.
              </p>
              <br />
              <p>
                <span className="font-bold">TCPA Consent:</span> You expressly
                consent to receiving calls and messages, including auto-dialed
                and pre-recorded message calls, and SMS messages (including text
                messages) from us, our affiliates, agents and others calling at
                their request or on their behalf, at any telephone numbers that
                you have provided or may provide in the future (including any
                cellular telephone numbers). Your cellular or mobile telephone
                provider will charge you according to the type of plan you
                carry.
              </p>
              <br />
              <p>
                <span className="font-bold">Withdrawing Consent:</span> You may
                not withdraw such consent as long as you have outstanding any
                investments made through the Site; provided, however, that you
                may withdraw consent to receive electronic delivery of IRS
                Forms, as more fully described in the Subscription document(s)
                applicable to you. If you have no outstanding investments made
                through the Site and wish to withdraw consent to doing business
                electronically, we will terminate your registered user account
                with us.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  How to Contact Us regarding Electronic Disclosures:
                </span>{" "}
                You can contact us via email at support@crowdfreighter.com. You
                may also reach us in writing to us at the following address:
                Open Chair Technologies, Inc., 728 EastPrairie Ave, Des Plaines,
                Illinois, 60016.
              </p>
              <br />
              <p>
                You will keep us informed of any change in your email or home
                mailing address so that you can continue to receive all
                Disclosures in a timely fashion. If your registered email
                address changes, you must notify us of the change by sending an
                email to support@crowdfreighter.com. You also agree to update
                your registered residence address and telephone number on the
                Site if they change.
              </p>
              <br />
              <p>
                You will print a copy of this Agreement for your records and you
                agree and acknowledge that you can access, receive and retain
                all Disclosures electronically sent via email or posted on the
                Site.
              </p>
            </div>
            <div className="mt-6">
              <h5>Securities Offering Matters</h5>
              <p className="mt-2">
                The securities offered on the Site have not been registered
                under the Securities Act, in reliance on the exemptive
                provisions of (i) Section 4(a)(2) of the Securities Act and Rule
                506 of Regulation D promulgated thereunder (“Regulation D” or
                “private placements”). Securities sold through private
                placements are restricted and not publicly traded, and are
                therefore illiquid. Neither the U.S. Securities and Exchange
                Commission nor any state securities commission or other
                regulatory authority has approved, passed upon or endorsed the
                merits of any offering on this Site.
              </p>
              <br />
              <p>
                Persons who are resident outside of the United States are not
                allowed access to investment opportunities on the Site. Our
                services (and certain pages of the Site) that relate to the
                viewing of actual investment opportunities or to making
                investments in the securities offered therein may not be used by
                any person or entity in any jurisdiction where the provision or
                use thereof would be contrary to applicable laws, rules or
                regulations of any governmental authority or where
                CrowdFreighter is not authorized to provide such information or
                services.
              </p>
              <br />
              <p>
                Investment overviews on the Site contain summaries of the
                purpose and principal business terms of the investment
                opportunities. Such summaries are intended for informational
                purposes only and do not purport to be complete, and each is
                qualified in its entirety by reference to the more detailed
                discussions contained in the investor document package relating
                to such investment opportunity, the most current version of each
                such document package may be found at CrowdFreighter.com/oc. The
                information contained in the Site has been prepared by
                CrowdFreighter without reference to any particular user’s
                investment requirements or financial situation, and potential
                investors are encouraged to consult with professional tax, legal
                and financial advisors before making any investment.
              </p>
            </div>
            <div className="mt-6">
              <h5>Eligibility</h5>
              <p className="mt-2">
                Private Placements. Only "accredited investors" as defined in
                Rule 501 of Regulation D are authorized to access such services
                and web pages relating to offerings conducted under Regulation D
                (such persons being "Accredited Investors"). In general, to
                qualify as an Accredited Investor, individuals must have a net
                worth of more than $1 million (excluding their primary
                residence), or gross income for each of the last two years of at
                least $200,000 ($300,000 jointly with their spouse) with the
                expectation of a similarly qualifying income during the current
                year.
              </p>
              <br />
              <p>
                Covered Persons. You may not become a beneficial owner of 20% or
                more of any issuer’s outstanding voting equity securities (an
                “Issuer Covered Person”) without becoming subject to certain
                “bad actor” disqualifying events described in Rule 506(d) of
                Regulation D or Rule 262(a) of Regulation A (a “Disqualifying
                Event”). You represent that you are not subject to a
                Disqualifying Event and that you will promptly notify
                CrowdFreighter in writing should any Disqualifying Events be
                applicable to you. CrowdFreighter is not liable or responsible
                for making Rule 506(c) under Regulation D or Rule 262(e)
                disclosures under Regulation A, nor for determining whether any
                Issuer Covered Person is subject to a Disqualifying Event.
              </p>
              <br />
              <p>
                Documentation. You may be required to provide supporting
                documents to issuers that provide proof that you are an
                Accredited Investor and as to your status with respect to being
                an Issuer Covered Person. Such proof will require your
                completion of a subscription agreement and may require
                completion of a questionnaire and satisfactory background
                information screening. Your failure to provide any information
                and documentation requested to confirm your status will be cause
                for CrowdFreighter to immediately discontinue your use of the
                Service by preventing your access to the Website and the
                Service.
              </p>
            </div>
            <div className="mt-6">
              <h5>Securities Law Disclosures</h5>
              <p className="mt-2">
                You are urged to review our full disclosure and offering
                circulars with respect to the securities offerings on the Site
                as well as the services provided by CrowdFreighter Advisors.
              </p>
            </div>
            <div className="mt-6">
              <h5>Intellectual Property Rights</h5>
              <p className="mt-2">
                <span className="font-bold">
                  Service Content, Software and Trademarks:
                </span>{" "}
                You acknowledge and agree that the Service may contain content
                or features (“Service Content”) that are protected by copyright,
                patent, trademark, trade secret or other proprietary rights and
                laws. Except as expressly authorized by CrowdFreighter, you
                agree not to modify, copy, frame, scrape, rent, lease, loan,
                sell, distribute or create derivative works based on the Service
                or the Service Content, in whole or in part, except that the
                foregoing does not apply to your own User Content (as defined
                below) that you legally upload to the Service. In connection
                with your use of the Service you will not engage in or use any
                data mining, robots, scraping or similar data gathering or
                extraction methods. If you are blocked by CrowdFreighter from
                accessing the Service (including by blocking your IP address),
                you agree not to implement any measures to circumvent such
                blocking (e.g., by masking your IP address or using a proxy IP
                address). Any use of the Service or the Service Content other
                than as specifically authorized herein is strictly prohibited.
                The technology and software underlying the Service or
                distributed in connection therewith are the property of
                CrowdFreighter, our affiliates and our partners (the
                “Software”). You agree not to copy, modify, create a derivative
                work of, reverse engineer, reverse assemble or otherwise attempt
                to discover any source code, sell, assign, sublicense, or
                otherwise transfer any right in the Software. Any rights not
                expressly granted herein are reserved by CrowdFreighter.
              </p>
              <br />
              <p>
                The CrowdFreighter name and logos are trademarks and service
                marks of CrowdFreighter (collectively the “Crowdfreighter
                Trademarks”), whether or not registered as such. Other company,
                product, and service names and logos used and displayed via the
                Service may be trademarks or service marks of their respective
                owners who may or may not endorse or be affiliated with or
                connected to CrowdFreighter. Nothing in this Terms of Service or
                the Service should be construed as granting, by implication,
                estoppel, or otherwise, any license or right to use any of
                CrowdFreighter Trademarks displayed on the Service, without our
                prior written permission in each instance. All goodwill
                generated from the use of CrowdFreighter Trademarks will inure
                to our exclusive benefit.
              </p>
              <br />
              <p>
                <span className="font-bold">Third Party Material:</span> Under
                no circumstances will CrowdFreighter be liable in any way for
                any content or materials of any third parties (including users),
                including, but not limited to, for any errors or omissions in
                any content, or for any loss or damage of any kind incurred as a
                result of the use of any such content. You acknowledge that
                CrowdFreighter does not pre-screen content, but that
                CrowdFreighter and its designees will have the right (but not
                the obligation) in their sole discretion to refuse or remove any
                content that is available via the Service. Without limiting the
                foregoing, CrowdFreighter and its designees will have the right
                to remove any content that violates these Terms of Service or is
                deemed by CrowdFreighter, in its sole discretion, to be
                otherwise objectionable. You agree that you must evaluate, and
                bear all risks associated with, the use of any content,
                including any reliance on the accuracy, completeness, or
                usefulness of such content.
              </p>
              <br />
              <p>
                <span className="font-bold">
                  User Content Transmitted Through the Service:
                </span>{" "}
                With respect to the content, data or other materials you input
                or upload through the Service (collectively, “User Content”),
                you represent and warrant that you own all right, title and
                interest in and to such User Content, including, without
                limitation, all copyrights and rights of publicity contained
                therein. You hereby grant and will grant CrowdFreighter and its
                affiliated companies a nonexclusive, worldwide, royalty free,
                fully paid up, transferable, sublicensable, perpetual,
                irrevocable license to copy, display, upload, perform,
                distribute, store, modify and otherwise use your User Content in
                connection with the operation of the Service, and the
                advertising, marketing and promotion thereof.
              </p>
              <br />
              <p>
                You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback or other information about the
                Service (“Submissions”), provided by you to CrowdFreighter are
                non-confidential and CrowdFreighter will be entitled to the
                unrestricted use and dissemination of these Submissions for any
                purpose, commercial or otherwise, without acknowledgment or
                compensation to you.
              </p>
              <br />
              <p>
                You acknowledge and agree that CrowdFreighter may preserve
                content and may also disclose content if required to do so by
                law or in the good faith belief that such preservation or
                disclosure is reasonably necessary to: (a) comply with legal
                process, applicable laws or government requests; (b) enforce
                these Terms of Service; (c) respond to claims that any content
                violates the rights of third parties; or (d) protect the rights,
                property, or personal safety of CrowdFreighter, its users and
                the public. You understand that the technical processing and
                transmission of the Service, including your content, may involve
                (a) transmissions over various networks; and (b) changes to
                conform and adapt to technical requirements of connecting
                networks or devices.
              </p>
            </div>
            <div className="mt-6">
              <h5>Third Party Websites</h5>
              <p className="mt-2">
                The Service may provide, or third parties may provide, links or
                other access to other sites and resources on the Internet.
                CrowdFreighter has no control over such sites and resources and
                CrowdFreighter is not responsible for and does not endorse such
                sites and resources. You further acknowledge and agree that
                CrowdFreighter will not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any
                content, events, goods or services available on or through any
                such site or resource. Any dealings you have with third parties
                found while using the Service are between you and the third
                party, and you agree that CrowdFreighter is not liable for any
                loss or claim that you may have against any such third party.
              </p>
            </div>
            <div className="mt-6">
              <h5>Indemnity and Release</h5>
              <p className="mt-2">
                You agree to release, indemnify and hold CrowdFreighter and its
                affiliates and their officers, employees, directors and agents
                (collectively, “Indemnitees”) harmless from any and all losses,
                damages, expenses, including reasonable attorneys’ fees, rights,
                claims, actions of any kind and injury (including death) arising
                out of or relating to your use of the Service, any User Content,
                your connection to the Service, your violation of these Terms of
                Service or your violation of any rights of another.
                Notwithstanding the foregoing, you will have no obligation to
                indemnify or hold harmless any Indemnitee from or against any
                liability, losses, damages or expenses incurred as a result of
                any action or inaction of such Indemnitee. If you are a
                California resident, you waive California Civil Code Section
                1542, which says: “A general release does not extend to claims
                which the creditor does not know or suspect to exist in his
                favor at the time of executing the release, which if known by
                him must have materially affected his settlement with the
                debtor.” If you are a resident of another jurisdiction, you
                waive any comparable statute or doctrine.
              </p>
            </div>
            <div className="mt-6">
              <h5>Disclaimers</h5>
              <p className="mt-2">
                YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
                PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. CROWDFREIGHTER
                EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE AND NON- INFRINGEMENT.
              </p>
              <br />
              <p>
                CROWDFREIGHTER MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET
                YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED,
                TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE
                OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR
                RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES,
                INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
                THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
              </p>
            </div>
            <div className="mt-6">
              <h5>Limitation of Liability</h5>
              <p className="mt-2">
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT CROWDFREIGHTER WILL NOT
                BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT
                NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER
                INTANGIBLE LOSSES (EVEN IF CROWDFREIGHTER HAS BEEN ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT,
                TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM:
                (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST
                OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM
                ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED
                OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR
                FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF
                YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY
                THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO
                THE SERVICE. IN NO EVENT WILL CROWDFREIGHTER’S TOTAL LIABILITY
                TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE
                AMOUNT YOU HAVE PAID CROWDFREIGHTER IN THE LAST SIX (6) MONTHS,
                OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
              </p>
              <br />
              <p>
                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
                CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
                FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
                THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE
                ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH
                ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR
                SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
              </p>
              <br />
              <p>
                IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
                “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF LIABILITY” ARE
                INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF
                THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS
                HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY,
                THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF
                THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
              </p>
            </div>
            <div className="mt-6">
              <h5>
                Dispute Resolution By Binding Arbitration: PLEASE READ THIS
                SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
              </h5>
              <br />
              <p>
                <ul className="list-none list-inside">
                  <li>
                    <span className="font-bold">a) Agreement to Arbitrate</span>
                    <br />
                    This Dispute Resolution by Binding Arbitration section is
                    referred to in this Terms of Service as the “Arbitration
                    Agreement.” You agree that any and all disputes or claims
                    that have arisen or may arise between you and
                    CrowdFreighter, whether arising out of or relating to this
                    Terms of Service (including any alleged breach thereof), the
                    Service, any advertising, any aspect of the relationship or
                    transactions between us, shall be resolved exclusively
                    through final and binding arbitration, rather than a court,
                    in accordance with the terms of this Arbitration Agreement,
                    except that you may assert individual claims in small claims
                    court, if your claims qualify. Further, this Arbitration
                    Agreement does not preclude you from bringing issues to the
                    attention of federal, state, or local agencies, and such
                    agencies can, if the law allows, seek relief against us on
                    your behalf. You agree that, by entering into this Terms of
                    Service, you and CrowdFreighter are each waiving the right
                    to a trial by jury or to participate in a class action. Your
                    rights will be determined by a neutral arbitrator, not a
                    judge or jury. The Federal Arbitration Act governs the
                    interpretation and enforcement of this Arbitration
                    Agreement.
                  </li>
                  <li>
                    <span className="font-bold">
                      b) Prohibition of Class and Representative Actions and
                      Non-Individualized Relief
                    </span>
                    <br />
                    YOU AND CROWDFREIGHTER AGREE THAT EACH OF US MAY BRING
                    CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT
                    AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                    REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND
                    CROWDFREIGHTER AGREE OTHERWISE, THE ARBITRATOR MAY NOT
                    CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS
                    AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                    CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
                    ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE,
                    AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL
                    PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
                    PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL
                    CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE
                    ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER
                    APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY
                    OF THIS PROVISION.
                  </li>
                  <li>
                    <span className="font-bold">
                      c) Pre-Arbitration Dispute Resolution
                    </span>
                    <br />
                    CrowdFreighter is always interested in resolving disputes
                    amicably and efficiently, and most customer concerns can be
                    resolved quickly and to the customer’s satisfaction by
                    emailing customer support at support@crowdfreighter.com. If
                    such efforts prove unsuccessful, a party who intends to seek
                    arbitration must first send to the other, by certified mail,
                    a written Notice of Dispute (“Notice”). The Notice to
                    CrowdFreighter should be sent to 728 EastPrairie Ave, Des
                    Plaines, Illinois, 60016 (“Notice Address”). The Notice must
                    (i) describe the nature and basis of the claim or dispute
                    and (ii) set forth the specific relief sought. If
                    CrowdFreighter and you do not resolve the claim within sixty
                    (60) calendar days after the Notice is received, you or
                    CrowdFreighter may commence an arbitration proceeding.
                    During the arbitration, the amount of any settlement offer
                    made by CrowdFreighter or you shall not be disclosed to the
                    arbitrator until after the arbitrator determines the amount,
                    if any, to which you or CrowdFreighter is entitled.
                  </li>
                  <li>
                    <span className="font-bold">d) Arbitration Procedure</span>
                    <br />
                    Arbitration will be conducted by a neutral arbitrator in
                    accordance with the American Arbitration Association’s
                    (“AAA”) rules and procedures, including the AAA’s Consumer
                    Arbitration Rules (collectively, the “AAA Rules”), as
                    modified by this Arbitration Agreement. For information on
                    the AAA, please visit its website, http://www.adr.org.
                    Information about the AAA Rules and fees for consumer
                    disputes can be found at the AAA’s consumer arbitration
                    page, http://www.adr.org/consumer_arbitration. If there is
                    any inconsistency between any term of the AAA Rules and any
                    term of this Arbitration Agreement, the applicable terms of
                    this Arbitration Agreement will control unless the
                    arbitrator determines that the application of the
                    inconsistent Arbitration Agreement terms would not result in
                    a fundamentally fair arbitration. The arbitrator must also
                    follow the provisions of these Terms of Service as a court
                    would. All issues are for the arbitrator to decide,
                    including, but not limited to, issues relating to the scope,
                    enforceability, and arbitrability of this Arbitration
                    Agreement. Although arbitration proceedings are usually
                    simpler and more streamlined than trials and other judicial
                    proceedings, the arbitrator can award the same damages and
                    relief on an individual basis that a court can award to an
                    individual under the Terms of Service and applicable law.
                    Decisions by the arbitrator are enforceable in court and may
                    be overturned by a court only for very limited reasons.
                    <br />
                    <br />
                    Unless CrowdFreighter and you agree otherwise, any
                    arbitration hearings will take place in Chicago, Illinois.
                    If the parties are unable to agree on a location, the
                    determination shall be made by AAA. If your claim is for
                    $10,000 or less, CrowdFreighter agrees that you may choose
                    whether the arbitration will be conducted solely on the
                    basis of documents submitted to the arbitrator, through a
                    telephonic hearing, or by an in-person hearing as
                    established by the AAA Rules. If your claim exceeds $10,000,
                    the right to a hearing will be determined by the AAA Rules.
                    Regardless of the manner in which the arbitration is
                    conducted, the arbitrator shall issue a reasoned written
                    decision sufficient to explain the essential findings and
                    conclusions on which the award is based.
                  </li>
                  <li>
                    <span className="font-bold">e) Costs of Arbitration</span>
                    <br />
                    Payment of all filing, administration, and arbitrator fees
                    (collectively, the “Arbitration Fees”) will be governed by
                    the AAA Rules, unless otherwise provided in this Arbitration
                    Agreement. If the value of the relief sought is $75,000 or
                    less, at your request, CrowdFreighter will pay all
                    Arbitration Fees. If the value of relief sought is more than
                    $75,000 and you are able to demonstrate to the arbitrator
                    that you are economically unable to pay your portion of the
                    Arbitration Fees or if the arbitrator otherwise determines
                    for any reason that you should not be required to pay your
                    portion of the Arbitration Fees, CrowdFreighter will pay
                    your portion of such fees. In addition, if you demonstrate
                    to the arbitrator that the costs of arbitration will be
                    prohibitive as compared to the costs of litigation,
                    CrowdFreighter will pay as much of the Arbitration Fees as
                    the arbitrator deems necessary to prevent the arbitration
                    from being cost-prohibitive. Any payment of attorneys’ fees
                    will be governed by the AAA Rules.
                  </li>
                  <li>
                    <span className="font-bold">f) Confidentiality</span>
                    <br /> All aspects of the arbitration proceeding, and any
                    ruling, decision, or award by the arbitrator, will be
                    strictly confidential for the benefit of all parties.
                  </li>
                  <li>
                    <span className="font-bold">g) Severability</span>
                    <br /> If a court or the arbitrator decides that any term or
                    provision of this Arbitration Agreement (other than the
                    subsection (b) titled “Prohibition of Class and
                    Representative Actions and Non-Individualized Relief” above)
                    is invalid or unenforceable, the parties agree to replace
                    such term or provision with a term or provision that is
                    valid and enforceable and that comes closest to expressing
                    the intention of the invalid or unenforceable term or
                    provision, and this Arbitration Agreement shall be
                    enforceable as so modified. If a court or the arbitrator
                    decides that any of the provisions of subsection (b) above
                    titled “Prohibition of Class and Representative Actions and
                    Non-Individualized Relief” are invalid or unenforceable,
                    then the entirety of this Arbitration Agreement shall be
                    null and void, unless such provisions are deemed to be
                    invalid or unenforceable solely with respect to claims for
                    public injunctive relief. The remainder of the Terms of
                    Service will continue to apply.
                  </li>
                  <li>
                    <span className="font-bold">
                      g) Future Changes to Arbitration Agreement
                    </span>
                    <br />
                    Notwithstanding any provision in this Terms of Service to
                    the contrary, CrowdFreighter agrees that if it makes any
                    future change to this Arbitration Agreement (other than a
                    change to the Notice Address) while you are a user of the
                    Service, you may reject any such change by sending
                    CrowdFreighter written notice within thirty (30) Calendar
                    days of the change to the Notice Address provided above. By
                    rejecting any future change, you are agreeing that you will
                    arbitrate any dispute between us in accordance with the
                    language of this Arbitration Agreement as of the date you
                    first accepted these Terms of Service (or accepted any
                    subsequent changes to these Terms of Service).
                  </li>
                  <li>
                    <span className="font-bold">
                      i) Compliance with Federal Securities Laws
                    </span>
                    <br />
                    BY AGREEING TO BE SUBJECT TO THE ARBITRATION PROVISION
                    CONTAINED IN THIS AGREEMENT, INVESTORS WILL NOT BE DEEMED TO
                    WAIVE CROWDFREIGHTER’S COMPLIANCE WITH THE FEDERAL
                    SECURITIES LAWS AND THE RULES AND REGULATIONS PROMULGATED
                    THEREUNDER.
                  </li>{" "}
                </ul>
              </p>
            </div>
            <div className="mt-6">
              <h5>Termination</h5>
              <p className="mt-2">
                You agree that CrowdFreighter, in its sole discretion, may
                suspend or terminate your account (or any part thereof) or use
                of the Service and remove and discard any content within the
                Service, for any reason, including, without limitation, for lack
                of use or if CrowdFreighter believes that you have violated or
                acted inconsistently with the letter or spirit of these Terms of
                Service. Any suspected fraudulent, abusive or illegal activity
                that may be grounds for termination of your use of Service, may
                be referred to appropriate law enforcement authorities.
                CrowdFreighter may also in its sole discretion and at any time
                discontinue providing the Service, or any part thereof, with or
                without notice. You agree that any termination of your access to
                the Service under any provision of this Terms of Service may be
                effected without prior notice, and acknowledge and agree that
                CrowdFreighter may immediately deactivate or delete your account
                and all related information and files in your account and/or bar
                any further access to such files or the Service. Further, you
                agree that CrowdFreighter will not be liable to you or any third
                party for any termination of your access to the Service.
              </p>
            </div>
            <div className="mt-6">
              <h5>General</h5>
              <p className="mt-2">
                These Terms of Service constitute the entire agreement between
                you and CrowdFreighter and govern your use of the Service,
                superseding any prior agreements between you and CrowdFreighter
                with respect to the Service. You also may be subject to
                additional terms and conditions that may apply when you use
                affiliate or third party services, third party content or third
                party software. These Terms of Service will be governed by the
                laws of the State of Delaware without regard to its conflict of
                law provisions. With respect to any disputes or claims not
                subject to arbitration, as set forth above, you and
                CrowdFreighter agree to submit to the personal and exclusive
                jurisdiction of (i) the United States District Court for the
                Eastern District of Virginia (Alexandria division), or (ii)
                solely to the extent there is no applicable federal jurisdiction
                over such dispute or matter, in the Circuit Court for Fairfax
                County, Virginia. The failure of CrowdFreighter to exercise or
                enforce any right or provision of these Terms of Service will
                not constitute a waiver of such right or provision. If any
                provision of these Terms of Service is found by a court of
                competent jurisdiction to be invalid, the parties nevertheless
                agree that the court should endeavor to give effect to the
                parties’ intentions as reflected in the provision, and the other
                provisions of these Terms of Service remain in full force and
                effect. You agree that regardless of any statute or law to the
                contrary, any claim or cause of action arising out of or related
                to use of the Service or these Terms of Service must be filed
                within one (1) year after such claim or cause of action arose or
                be forever barred. A printed version of this agreement and of
                any notice given in electronic form will be admissible in
                judicial or administrative proceedings based upon or relating to
                this agreement to the same extent and subject to the same
                conditions as other business documents and records originally
                generated and maintained in printed form. You may not assign
                this Terms of Service without the prior written consent of
                CrowdFreighter, but CrowdFreighter may assign or transfer this
                Terms of Service, in whole or in part, without restriction. The
                section titles in these Terms of Service are for convenience
                only and have no legal or contractual effect. Notices to you may
                be made via either email or regular mail. The Service may also
                provide notices to you of changes to these Terms of Service or
                other matters by displaying notices or links to notices
                generally on the Service.
              </p>
            </div>
            <div className="mt-6">
              <h5>Your Privacy</h5>
              <p className="mt-2">
                At CrowdFreighter, we respect the privacy of our users. For
                details please see the Privacy Policy available at
                www.crowdfreighter.com/privacy-policy. By using the Service, you
                consent to our collection and use of personal data as outlined
                therein.
              </p>
            </div>
            <div className="mt-6">
              <h5>Notice for California Users</h5>
              <p className="mt-2">
                Under California Civil Code Section 1789.3, users of the Service
                from California are entitled to the following specific consumer
                rights notice: The Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs may be contacted in writing at 1625 North Market Blvd.,
                Suite N 112, Sacramento, CA 95834, or by telephone at (916)
                445-1254 or (800) 952-5210. You may contact us at Open Chair
                Technologies, Inc., 728 E Prairie Ave, Des Plaines, Illinois,
                60016.
              </p>
            </div>
            <div className="mt-6">
              <h5>Questions? Concerns? Suggestions?</h5>
              <p className="mt-2">
                Please contact us at support@crowdfreighter.com to report any
                violations of these Terms of Service or to pose any questions
                regarding this Terms of Service or the Service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
