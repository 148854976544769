import { QueryClient, QueryClientProvider } from "react-query";

import * as Analytics from "./analytics";
import * as Auth from "./auth";
import * as Axios from "./axios";
import * as Reporting from "./reporting";

type Props = {
  children: JSX.Element;
};

const queryClient = new QueryClient();

export default function AppProviders({ children }: Props) {
  return (
    <Auth.AuthProvider>
      <Reporting.ReportsProvider>
        <Analytics.AnalyticsProvider>
          <Axios.AxiosProvider>
            <QueryClientProvider client={queryClient}>
              {children}
            </QueryClientProvider>
          </Axios.AxiosProvider>
        </Analytics.AnalyticsProvider>
      </Reporting.ReportsProvider>
    </Auth.AuthProvider>
  );
}
