import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login";
import AppleLogin from "react-apple-login";
import * as Sentry from "@sentry/browser";

import * as Auth from "providers/auth";
import TextInput from "components/TextInput";
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from "components/Button";

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const { requestOtp, signInApple, signInGoogle } = Auth.useAuth();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const isLogin = location.pathname.includes("/login");

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: async ({ email }) => {
      try {
        setSubmitting(true);

        const user = await requestOtp(email);

        return navigate(`/verify?email=${user?.data.email}`);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  async function authenticateOauth(
    provider: string,
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) {
    try {
      const user =
        provider === "google"
          ? await signInGoogle(response)
          : await signInApple(response);

      if (user?.id && !user.full_name) {
        return navigate(`/on-boarding/info`);
      }

      if (user?.id) {
        return navigate(`/dashboard`);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  return (
    <>
      <Helmet>
        <title>CrowdFreighter | Login</title>
        <meta property="og:title" content="CrowdFreighter | Login" />
      </Helmet>
      <div className="sm:px-16 px-4 flex flex-grow w-full">
        <div
          className="flex w-full justify-center mt-10 pb-20"
          data-testid="login-container"
        >
          <div className="flex flex-col lg:w-1/2 sm:w-2/3 w-full">
            <div className="flex flex-col mb-2 items-center">
              <h2>{isLogin ? `Login` : `Register`}</h2>
            </div>
            {false && (
              <>
                <div className="mt-6">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
                    render={(renderProps) => (
                      <Button
                        className="w-full"
                        text="Continue with Google"
                        type="submit"
                        variant={BUTTON_VARIANTS.GOOGLE}
                        size={BUTTON_SIZES.MEDIUM}
                        submitting={submitting}
                        onClick={renderProps.onClick}
                      />
                    )}
                    onSuccess={(response) =>
                      authenticateOauth("google", response)
                    }
                  />
                  <AppleLogin
                    clientId={process.env.REACT_APP_APPLE_CLIENT_ID || ""}
                    render={(renderProps) => (
                      <Button
                        className="mt-2 w-full"
                        text="Continue with Apple"
                        type="submit"
                        variant={BUTTON_VARIANTS.APPLE}
                        size={BUTTON_SIZES.MEDIUM}
                        submitting={submitting}
                        onClick={renderProps.onClick}
                      />
                    )}
                    callback={(response) =>
                      authenticateOauth("apple", response)
                    }
                    usePopup={true}
                    responseMode="form_post"
                    redirectURI={`${process.env.REACT_APP_APP_URL}/login`}
                  />
                </div>
                <div className="flex w-full items-center justify-between my-4">
                  <div className="border-b border-mono-100 border-opacity-20 w-2/5" />
                  <p className="forms text-mono-black text-opacity-20">OR</p>
                  <div className="border-b border-mono-100 border-opacity-20 w-2/5" />
                </div>
              </>
            )}
            <div className="mt-4">
              <form onSubmit={formik.handleSubmit}>
                <TextInput
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="hello@crowdfreighter.com"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.errors.email || error}
                  className="w-full"
                />
                <div className="flex lg:flex-row flex-col justify-between mt-6 lg:items-center">
                  <Button
                    className="w-full lg:w-auto"
                    text={isLogin ? "Login" : "Register"}
                    type="submit"
                    size={BUTTON_SIZES.MEDIUM}
                    submitting={submitting}
                  />
                  <div className="flex lg:mt-0 md:mt-3 md:justify-start mt-6 justify-center">
                    <p className="text-sm">
                      {isLogin ? `New to CrowdFreighter?` : `Have an account?`}{" "}
                      <Link
                        className="cursor-pointer"
                        to={isLogin ? "/register" : "/login"}
                      >
                        {isLogin ? `Register` : `Login`}
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-8">
              <p className="caption text-center">
                By {isLogin ? `logging in` : `registering`}, you agree to our{" "}
                <Link className="caption" to="/terms-of-service">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link className="caption" to="/privacy-policy">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Required")
});
